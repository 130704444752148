import isNull from 'lodash.isnull';
import {translate} from '@ion/core/src/locale/locale';
import {isValidEmail} from '@ion/core/src/utils/Validators';

/**
 * Provide the list of Domains to prevent the user to 
 * create any account with this domain list. 
 */
export const excludedDomains = {
  'gmail.com'      : true,
  'yahoo.com'      : true,
  'outlook.com'    : true,
  'aol.com'        : true,
  'icloud.com'     : true,
  'hotmail.com'    : true,
  'mail.com'       : true,
  'protonmail.com' : true
};

/**
 * Validate the provided email is exist in the prevented list 
 * from excludedDomains array. 
 */
export const isValidSSODomain = (email: string): boolean => {
  if (isValidEmail(email)) {
    const domain = email?.split('@')?.[1];
    return !excludedDomains[domain];
  }
  return false;
};

/**
 * Validate the provided email and return the related warning messages.
 */
export const validateSSODomain = (email: string): string | null => {
  if (isNull(email)) {
    return translate('common:editors.shared.required');
  } else if (!isValidEmail(email)) {
    return translate('common:editors.shared.invalidEmail');
  } else if (!isValidSSODomain(email)) {
    return translate('common:editors.shared.isValidSSODomain');
  }
  return null;
};
